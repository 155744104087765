const { redom } = window;
const { el } = redom;

export default class NotificationButton {
  constructor({ api }) {
    this.el = el(
      '#notifications-button',
      el('i.ti.ti-notification'),
      (this.indicator = el('.indicator'))
    );

    this.el.onclick = () => {
      if (api.menuOpened) {
        api.menuOpened = false;
        api.menuSection = '';
      } else {
        api.menuOpened = true;
        api.menuSection = 'notifications';
      }
      api.update();
    };
  }

  update(data) {
    const { notifications } = data;
    const notificationsCount = notifications && notifications.length;

    this.indicator.textContent = notificationsCount;

    this.indicator.style.display = notificationsCount ? '' : 'none';
  }
}
