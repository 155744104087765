const { redom } = window;
const { el } = redom;

export default class Browse {
  constructor ({ api, app }) {
    this.app = app;
    this.api = api;
    this.el = el('.browse',
      this.startTime = el('input.start', { type: 'datetime-local' }),
      this.endTime = el('input.end', { type: 'datetime-local' })
    );
    const todayStart = new Date();
    todayStart.setHours(0);
    todayStart.setMinutes(0);
    todayStart.setSeconds(0);
    todayStart.setMilliseconds(0);
    this.startTime.value = toLocalDateString(todayStart);

    const todayEnd = new Date();
    todayEnd.setHours(23);
    todayEnd.setMinutes(59);
    todayEnd.setSeconds(59);
    todayEnd.setMilliseconds(999);
    this.endTime.value = toLocalDateString(todayEnd);

    ['startTime', 'endTime'].forEach(id => {
      this[id].oninput = () => {
        this.trigger();
      };
    });

    this.trigger();
  }

  trigger () {
    this.api.opsPlanListStart = new Date(this.startTime.value);
    this.api.opsPlanListEnd = new Date(this.endTime.value);

    if (this.app.map.map) {
      const source = this.app.map.map.getSource('operationplans');
      if (source) {
        source._data = `${source._data.split('.geojson')[0]}.geojson?start=${this.api.opsPlanListStart.toISOString()}&end=${this.api.opsPlanListEnd.toISOString()}`;
        source.setData(source._data);
      }
    }
  }
}

function toLocalDateString (date) {
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
}

function pad (num) {
  return `0${num}`.slice(-2);
}
