const { redom } = window;
const { el } = redom;

export class Help {
  constructor ({ i18n, api }) {
    this.i18n = i18n;
    this.el = el(
      '.help',
      (this.searchHelp = el('.searchhelp')),
      (this.timeHelp = el('.timehelp')),
      (this.onlineHelp = el('.onlinehelp')),
      (this.menuHelp = el('.menuhelp')),
      (this.planHelp = el('.planhelp'))
    );
    let count = 0;
    ['search', 'time', 'online', 'menu', 'plan'].forEach((item) => {
      const $item = this[item + 'Help'];
      $item.onclick = () => {
        $item.style.display = 'none';
        count++;

        if (count === 4) {
          api.help = false;
          api.update();
        }
      };
    });
  }

  update (api) {
    const { i18n } = this;

    const { ENV } = window;
    const { HIDE_CLOCK } = ENV;

    this.timeHelp.style.display = HIDE_CLOCK ? 'none' : '';

    this.searchHelp.innerHTML = i18n('help.search');
    this.timeHelp.innerHTML = i18n('help.time');
    this.onlineHelp.innerHTML = i18n('help.online');
    this.menuHelp.innerHTML = i18n('help.menu');
    this.planHelp.innerHTML = i18n('help.plan');
  }
}

export class HelpButton {
  constructor ({ api }) {
    this.el = el('.helpbutton', el('i.ti.ti-question-mark'));
    this.el.onclick = () => {
      api.menuOpened = false;
      api.drawPlan = false;
      api.createPlan = false;
      api.timeOpened = false;
      api.help = !api.help;
      api.update();
    };
  }

  update (api) {
    const { menuOpened, drawPlan, createPlan, timeOpened } = api;

    api.help
      ? this.el.classList.add('active')
      : this.el.classList.remove('active');

    if (menuOpened || drawPlan || createPlan || timeOpened) {
      if (api.help) {
        api.help = false;
        api.update();
      }
      this.el.classList.remove('active');
      this.el.style.opacity = 0.5;
    } else {
      this.el.style.opacity = '';
    }
  }
}
