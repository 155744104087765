import { Preferences } from '@capacitor/preferences';

const { XMLHttpRequest } = window;

export async function request (url, options) {
  const id = await getItem('id');

  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.open(options.method, url, true);
    req.setRequestHeader('x-id', id || '');
    if (options.headers) {
      for (const key in options.headers) {
        req.setRequestHeader(key, options.headers[key]);
      }
    }
    req.onload = function onload () {
      if (req.status === 200) {
        resolve(req.responseText);
      } else {
        reject(req.responseText || req.status);
      }
    };
    req.onerror = function onerror (err) {
      reject(new Error(err));
    };

    options.body ? req.send(options.body) : req.send();
  });
}

export async function get (url, options) {
  return await request(url, {
    ...(options || {}),
    method: 'GET'
  });
}

export async function post (url, options) {
  return await request(url, {
    ...(options || {}),
    method: 'POST',
    headers: {
      ...((options || {}).headers || {}),
      'Content-Type': 'application/json'
    }
  });
}

async function getItem (key) {
  return (await Preferences.get({ key })).value;
}
