import roundWgs from './roundwgs.js';

const {
  alert,
  turf
} = window;

export default class Draw {
  constructor ({ map, drawControl, api, i18n }) {
    this.i18n = i18n;
    this.api = api;
    this.map = map;

    this.clickHandler = (e) => {
      if (!api.drawPlan) {
        return;
      }
      let { lng, lat } = e.lngLat;

      lng = roundWgs(lng);
      lat = roundWgs(lat);

      const current = api.currentDrawing;

      if (current.length && api.drawingMode !== 'circle') {
        if ((current[0].lat === lat && current[0].lng === lng)) {
          api.currentDrawing = [];
        }

        current.push({ lng, lat });

        if (api.drawingMode === 'polygon' && current.length > 2) {
          const data = [current.concat(current[0]).map(({ lng, lat }) => [lng, lat])];
          if (turf.kinks(turf.polygon(data)).features.length) {
            alert(i18n('draw.error.intersectingPolygon'));
            current.pop();
          }
        }
      } else {
        api.currentDrawing = [{ lng, lat }];
      }

      api.update();
    };
    this.map.on('click', this.clickHandler);
  }

  onunmount () {
    this.map.off('click', this.clickHandler);
  }
}
