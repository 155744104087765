import { post } from './request.js';
import uuid from './uuid.js';

const { redom } = window;
const { el } = redom;

const states = {
  PROPOSED: 'Proposed',
  APPROVED: 'Approved',
  TAKEOFFREQUESTED: 'Takeoff requested',
  ACTIVATED: 'Activated',
  CLOSED: 'Closed'
};

export default class Feature {
  constructor ({ api, i18n }) {
    this.i18n = i18n;
    this.api = api;
    this.el = el('.info-feature',
      el('table.table',
        el('tr',
          el('td.minwidth', this.legend = el('.legend')),
          el('td',
            this.name = el('.name'),
            this.state = el('.tag')
          ),
          el('td',
            this.alert = el('button.button.red.small', 'Alert')
          ),
          el('td',
            this.requestTakeoff = el('button.button.small', 'Request takeoff')
          ),
          el('td',
            this.terminatePlan = el('button.button.red.small', 'Terminate')
          )
        )
      )
    );
    const { user } = this.api.query;
    if (api.app.map.marker) {
      const { lng, lat } = api.app.map.marker.getLngLat();

      this.alert.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const alert = {
          free_text: 'string',
          history: [{
            state: 'NOT_ACKNOWLEDGED',
            time_stamp: new Date()
          }],
          location: JSON.stringify({ type: 'Point', coordinates: [lng, lat] }),
          message_id: uuid(),
          operation_plans: [
            plan.operationPlanId
          ],
          severity_type: 'ALERT',
          source: 'AVIA',
          time_sent: new Date(),
          type: 'OTHER_SEE_FREE_TEXT',
          uss_name: user || 'aviagof2'
        };
        try {
          await post(`https://aviamaps.com/utmdemo/sendalert?user=${user || 'aviagof2'}`, {
            body: JSON.stringify(alert)
          });
          this.api.app.map.reloadJSON('alerts');
        } catch (err) {
          return console.error(new Error(err));
        }
      };

      this.requestTakeoff.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const version = uuid();
        plan.state = 'TAKEOFFREQUESTED';
        plan.version = version;
        try {
          await post(`https://aviamaps.com/utmdemo/operationplan/notify2?user=${user || 'aviagof2'}`, {
            body: JSON.stringify(plan)
          });
          this.api.createPlan = false;
          this.api.update();
          this.api.app.map.reloadJSON('operationplans');
        } catch (err) {
          return console.error(new Error(err));
        }
      };

      this.terminatePlan.onclick = async () => {
        const plan = JSON.parse(this.data.properties.originalPlan);
        const version = uuid();
        plan.state = 'CLOSED';
        plan.version = version;
        try {
          await post(`https://aviamaps.com/utmdemo/operationplan/notify2?user=${user || 'aviagof2'}`, {
            body: JSON.stringify(plan)
          });
          this.api.createPlan = false;
          this.api.update();
          this.api.app.map.reloadJSON('operationplans');
        } catch (err) {
          return console.error(new Error(err));
        }
      };
    }
  }

  update (data) {
    const { i18n } = this;
    this.data = data;
    const { properties, source } = data;
    const { name, code, state, lower, upper } = properties;

    if (source === 'operationplans') {
      if (state === 'APPROVED') {
        this.requestTakeoff.style.display = '';
      } else {
        this.requestTakeoff.style.display = 'none';
      }
      this.terminatePlan.style.display = '';
      this.alert.style.display = '';
    } else {
      this.requestTakeoff.style.display = 'none';
      this.terminatePlan.style.display = 'none';
      this.alert.style.display = 'none';
    }

    if (source === 'uas') {
      const applicability = properties.applicability && (typeof properties.applicability === 'string') && JSON.parse(properties.applicability);
      const parsedApplicability = parseApplicability(applicability);

      const { justConflict, conflictType, objectType, objectId } = properties;

      const message = parseMessage(properties, i18n.LANG);

      if (justConflict) {
        if (
          message.includes(
            'Operation volumes cannot be VLOS when its bounding box size is larger than'
          ) || message.includes('VLOS flight maximum dimensions exceeded')
        ) {
          this.name.innerHTML = i18n('summary.error.VLOS_BOUNDING_BOX_SIZE_TOO_BIG');
        } else {
          if (conflictType === 'CONFLICTED_OP' && objectType === 'OPERATION_PLAN') {
            this.name.innerHTML = i18n('operationplan.conflict.conflictedOp.operationPlan', message).replace('{{objectId}}', objectId);
          } else {
            this.name.innerHTML = message;
          }
        }
        this.legend.style.backgroundColor = window.COLORS.PROHIBITED;
      } else {
        this.name.innerHTML = `<b>${name || ''}</b><br>${message ? `${message}<br>` : ''}${parsedApplicability ? `${parsedApplicability}<br>` : ''}${lower} &rarr; ${upper}`;

        const colors = window.COLORS;

        const { restriction, _rejecting } = properties;
        this.legend.style.backgroundColor = colors[_rejecting ? 'PROHIBITED' : restriction] || 'hsl(50, 50%, 40%)';
      }
    } else if (source === 'coordinate') {
      this.name.innerHTML = `<b>${i18n('coordinate')}</b><br>${name}</b>`;
      this.legend.style.backgroundColor = '';
      this.legend.innerHTML = '<i class="ti ti-map-pin-filled"></i>';
    } else {
      this.name.textContent = name || code;
      this.legend.style.backgroundColor = '';
      this.legend.textContent = '';
    }
    if (states[state]) {
      this.state.textContent = states[state];
      this.state.style.display = '';
    } else {
      this.state.style.display = 'none';
    }
  }
}

function parseMessage (properties, LANG) {
  try {
    const extendedProperties = (properties.extendedProperties && typeof properties.extendedProperties === 'string') ? JSON.parse(properties.extendedProperties) : properties.extendedProperties;
    const { localizedMessages = [] } = extendedProperties || {};
    const messages = {};

    for (const localizedMessage of localizedMessages) {
      const { language, message } = localizedMessage;

      messages[language] = message;
    }

    return messages[LANG] || messages.en || properties.message;
  } catch (err) {
    console.error(new Error(err));
  }

  return properties.message;
}

function parseApplicability (applicability) {
  const results = [];

  try {
    (applicability || []).forEach(applicability => {
      const { permanent, startDateTime, endDateTime, schedule } = applicability;

      if (permanent === 'YES') {
        if (startDateTime && endDateTime) {
          results.push(`${humanDate(new Date(startDateTime))}&ndash;${humanDate(new Date(endDateTime))}: H24`);
        } else {
          results.push('H24');
        }
      } else {
        try {
          const parsedSchedule = parseSchedule(schedule);

          if (startDateTime && endDateTime) {
            results.push(`${humanDate(new Date(startDateTime))}&ndash;${humanDate(new Date(endDateTime))}${parsedSchedule ? `: ${parsedSchedule}` : ''}`);
          } else {
            results.push(parsedSchedule);
          }
        } catch (err) {
          console.error(new Error(err));
        }
      }
    });
  } catch (err) {
    console.error(new Error(err));
  }

  return results.join('<br>');
}

function parseSchedule (schedule) {
  return schedule.map(item => {
    const { day, startTime, endTime } = item;
    return `${day.join(' ')} ${humanZTimerange(startTime, endTime)}`;
  }).join(', ');
}

function humanZTimerange (start, end) {
  return `${start.slice(0, -1)}&ndash;${end.slice(0, -1)} UTC`;
}

function humanDate (date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();

  return `${d}.${m}.${y}`;
}
