import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import getPlanArea from '../get-plan-area.js';
import uuid from '../uuid.js';
import { getItem, setItem, removeItem } from '../storage.js';
import { request, get, post } from '../request.js';
import { PushNotifications } from '@capacitor/push-notifications';

const {
  fetch,
  turf,
  requestAnimationFrame,
  location,
  Keycloak
} = window;

const platform = Capacitor.getPlatform();

export default class Api {
  constructor ({ i18n }) {
    this.i18n = i18n;
    this.query = getQuery();
    this.drawingMode = 'circle';
    this.currentDrawing = [];
    this.buffer = 50;
    // this.segment = 10;
    this.basemap = '';
    this.createPlan = false;
    this.menuSection = '';
    this.DATA = {
      alerts: [],
      telemetry: [],
      telemetryLookup: {}
    };
    this.drones = [];
    this.operationplans = [];
    this.operationplansLookup = {};
    this.authorizations = {};
    this.activations = {};
    this.activePlans = [];
    this.waitingPlans = [];
    this.closedPlans = [];
    this.rejectedPlans = [];
    this.cancelledPlans = [];
    this.loggedPlans = [];
    this.features = [];

    this.sortFeatures = (features = this.features) => {
      features.sort((a, b) => {
        const { lowerMeters: lowerMetersA, upperMeters: upperMetersA } = a.properties;
        const { lowerMeters: lowerMetersB, upperMeters: upperMetersB } = b.properties;

        if (lowerMetersA === lowerMetersB) {
          return upperMetersA - upperMetersB;
        } else {
          return lowerMetersA - lowerMetersB;
        }
      });

      const restrictionIndex = {
        NO_RESTRICTION: 0,
        CONDITIONAL: 1,
        REQ_AUTHORISATION: 2,
        PROHIBITED: 3
      };

      features.sort((a, b) => {
        const { restriction: restrictionA, _rejecting: _rejectingA } = a.properties;
        const { restriction: restrictionB, _rejecting: _rejectingB } = b.properties;
        const indexA = _rejectingA ? 3 : restrictionIndex[restrictionA];
        const indexB = _rejectingB ? 3 : restrictionIndex[restrictionB];

        return indexB - indexA;
      });
    };

    this.hello(this.query);

    CapacitorApp.addListener('appUrlOpen', async (event) => {
      const split = event.url.split('/');

      const action = split[2];
      const code = decodeURIComponent(split[3]);

      if (action === 'code') {
        this.hello({ code });
      } else if (action === 'logout') {
        this.hello({ logout: true });
      }

      try {
        Browser.close();
      } catch (err) {
        console.error(new Error(err));
      }
    });

    setTimeout(() => this.fetchPlans(), 60 * 1000);

    this.updateEnv();
  }

  updateEnv () {
    const {
      HOST
    } = window.ENV;

    fetch(HOST + 'env')
      .then(res => {
        if (res.ok) {
          return res;
        } else {
          setTimeout(() => {
            this.updateEnv();
          }, 5000);
        }
      })
      .then(res => res.json())
      .then(results => {
        for (const key in results) {
          if (key === 'APP_ID' || key === 'MOBILE') {
            continue;
          }
          window.ENV[key] = results[key];
        }
        this.update();
      })
      .catch(err => {
        console.error(new Error(err));
        setTimeout(() => {
          this.updateEnv();
        }, 5000);
      });
  }

  getPlanFeatures () {
    return new Promise((resolve) => {
      const json = this.uasData;
      const { routeArea } = getPlanArea(this);

      let union = routeArea.features[0];

      for (let i = 1; i < routeArea.features.length; i++) {
        const feature = routeArea.features[i];
        union = turf.union(union, feature);
      }

      const results = json.features
        .filter(feature => {
          try {
            return turf.intersect(turf.feature(feature.geometry), union);
          } catch (err) {
            console.error(new Error(err));
          }
          return false;
        });

      resolve(results);
    });
  }

  async fetchUAS (force, updates) {
    const {
      HOST
    } = window.ENV;

    if (!force && (this.lastUASFetchRequestAt > Date.now() - 5000)) {
      clearTimeout(this.fetching);
      this.fetchingUAS = setTimeout(() => this.fetchUAS(), 5000);
      return;
    }
    this.lastUASFetchRequestAt = Date.now();

    clearTimeout(this.fetching);
    this.fetchingUAS = setTimeout(() => this.fetchUAS(), 60 * 1000);

    let url = HOST + 'utm/uas.geojson';

    if (this.browseTime) {
      const start = this.browseTime;
      const end = this.browseTime;

      url += `?start=${start.toISOString()}&end=${end.toISOString()}&buffer=${this.browseBuffer}`;
    }

    if (updates) {
      this.isFetchingUAS = true;
    }

    fetch(url)
      .then(res => {
        if (updates) {
          this.isFetchingUAS = false;
        }
        if (!res.ok) throw new Error(res.status);
        return res;
      })
      .then(res => res.json())
      .then(geojson => {
        this.uasData = geojson;
        const uas = this.app.map.map.getSource('uas');
        uas && uas.setData(geojson);
      });
  }

  async fetchPlans (force) {
    const {
      HOST
    } = window.ENV;

    if (!force && (this.lastPlansFetchRequestAt > Date.now() - 5 * 1000)) {
      clearTimeout(this.fetching);
      this.fetchingPlans = setTimeout(() => this.fetchPlans(), 5 * 1000);
      return;
    }

    this.app.map.reloadJSON('operationplans');

    this.lastPlansFetchRequestAt = Date.now();

    clearTimeout(this.fetchingPlans);
    this.fetchingPlans = setTimeout(() => this.fetchPlans(), 60 * 1000);

    const { user } = this;

    try {
      if (user) {
        let url = `${HOST}utm/operationplans.json`;
        const id = await getItem('id');

        if (this.browseTime) {
          const start = this.browseTime;
          const end = this.browseTime;

          url += `?start=${start.toISOString()}&end=${end.toISOString()}`;
          if (id) {
            url += `&id=${id}`;
          }
        } else {
          if (id) {
            url += `?id=${id}`;
          }
        }

        const json = await get(url);
        const plans = JSON.parse(json);
        const activePlans = (plans || []).filter(plan => plan.state === 'ACTIVATED' && plan.state !== 'CLOSED');
        const waitingPlans = (plans || []).filter(plan => plan.state !== 'CLOSED' && plan.state !== 'ACTIVATED');
        const closedPlans = (plans || []).filter(plan => plan.state === 'CLOSED');

        this.operationplans = plans;
        this.operationplansLookup = plans.reduce((lookup, plan) => {
          const self = this;
          Object.defineProperties(plan, {
            authorization: {
              get () {
                return self.authorizations[this.operationPlanId];
              }
            },
            activation: {
              get () {
                return self.activations[this.operationPlanId];
              }
            },
            completed: {
              get () {
                return this.authorization && this.authorization.state === 'GRANTED';
              }
            },
            cancelled: {
              get () {
                if (this.authorization && this.authorization && this.authorization.conflicts && this.authorization.conflicts.length) {
                  return false;
                }
                return true;
              }
            },
            denied: {
              get () {
                if (this.activation && this.activation.state === 'DENIED') {
                  return true;
                }
                return false;
              }
            },
            rejected: {
              get () {
                if (this.state !== 'CLOSED') {
                  return false;
                }
                return true;
              }
            }
          });

          lookup[plan.operationPlanId] = plan;
          return lookup;
        }, {});
        this.activePlans = activePlans;
        this.waitingPlans = waitingPlans;
        this.closedPlans = closedPlans;
        this.update();

        if ((this.menuSection || '').includes('operationplan')) {
          await Promise.all(closedPlans.map(async plan => {
            for (const phase of ['authorization', 'activation']) {
              try {
                const result = await fetch(`${HOST}utm/${phase}/${plan.operationPlanId}`, {
                  priority: 'low'
                }).then(res => res.ok && res.json());
                this[phase + 's'][plan.operationPlanId] = result;

                if (result.state !== 'GRANTED' && result.state !== 'GRANTED_WITH_RESTRICTIONS') {
                  break;
                }
              } catch (err) {
                // no-op
              }
            }
          }));
          const loggedPlans = closedPlans.filter(plan => plan.completed);
          const rejectedPlans = [];
          const cancelledPlans = [];

          for (const plan of closedPlans.filter(plan => !plan.completed)) {
            if (!plan.cancelled && (plan.rejected || plan.denied)) {
              rejectedPlans.push(plan);
            } else {
              cancelledPlans.push(plan);
            }
          }

          this.loggedPlans = loggedPlans;
          this.cancelledPlans = cancelledPlans;
          this.rejectedPlans = rejectedPlans;
          this.update();
        }
      }
    } catch (err) {
      console.error(new Error(err));
    }
  }

  get allNotifications () {
    const lookup = {};
    return this.DATA.alerts
      .filter(alert => {
        const { _id } = alert;

        if (lookup[_id]) {
          return false;
        }
        lookup[_id] = true;
        return true;
      })
      .filter(alert => {
        const { operationPlanIds = [] } = alert;

        for (let i = 0; i < operationPlanIds.length; i++) {
          const id = operationPlanIds[i];

          if (this.operationplansLookup[id]) {
            return true;
          }
        }
        return false;
      }) || [];
  }

  get notifications () {
    return this.allNotifications.filter(notification => !notification.acknowledged);
  }

  async hello (args) {
    const {
      HOST,
      APP_ID,
      SKYZR_KEYCLOAK,
      SKYZR_CLIENT_ID,
      SKYZR_REALM,
      PUSH_NOTIFICATIONS
    } = window.ENV;

    try {
      clearTimeout(this.scheduleHello);
      this.scheduleHello = setTimeout(() => {
        this.hello();
      }, 60 * 1000);

      const { code: authorizationCode, logout } = args || {};

      if (authorizationCode) {
        const id = Date.now() + '.' + uuid();
        await setItem('id', id);
      }

      let url = `${HOST}auth/skyzr/hello`;

      if (platform !== 'web') {
        url += '?app';

        if (APP_ID) {
          url += '=' + APP_ID;
        }
      }

      if (logout != null) {
        await request(url, { method: 'DELETE' });
        await removeItem('id');
        if (location.search) {
          location.search = '?';
        } else {
          location.reload();
        }
        return;
      }

      const body = JSON.stringify({
        authorizationCode
      });

      const hello = await post(url, { body }).catch(err => console.error(new Error(err)));

      const parsed = JSON.parse(hello || '{}');

      if (authorizationCode) {
        if (location.search) {
          location.search = '';
        } else {
          location.reload();
        }
        return;
      }

      const { user, environments } = parsed;

      if (!user || user.error) {
        this.user = null;
        this.environments = null;
        this.update();

        if (platform === 'web' && !location.href.includes('?')) {
          const keycloak = new Keycloak({
            url: SKYZR_KEYCLOAK + '/auth',
            realm: SKYZR_REALM,
            clientId: SKYZR_CLIENT_ID
          });

          keycloak.init({
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri: HOST + 'silent-check-sso.html'
          }).then((authenticated) => {
            if (authenticated) {
              location.href = HOST + 'auth/skyzr/check-sso';
            }
          }).catch(err => {
            err && console.error(err);
          });
        }
        return;
      }

      this.user = user;
      this.environments = environments;
      this.operator = (user && user.company && user.company.registrationNumber) || user.email;
      this.update();

      if (PUSH_NOTIFICATIONS) {
        PushNotifications.requestPermissions().then(result => {
          if (result.receive === 'granted') {
            PushNotifications.register();
          }
        });

        PushNotifications.addListener('registration', async (token) => {
          const url = `${HOST}auth/skyzr/pushnotifications`;

          post(url, {
            body: JSON.stringify({
              token: token.value
            })
          }).then(noOp);
        });

        PushNotifications.addListener('registrationError',
          (error) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
        );

        PushNotifications.addListener('pushNotificationReceived',
          (notification) => {
            console.log('Push received: ' + JSON.stringify(notification));
          }
        );

        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification) => {
            console.log('Push action performed: ' + JSON.stringify(notification));
          }
        );
      }
    } catch (err) {
      console.error(new Error(err));
    }
  }

  async fetchDrones () {
    const {
      HOST
    } = window.ENV;

    try {
      if (!this.user) {
        this.drones = [];
        return;
      }
      if (!this.user.active) {
        this.drones = [];
        return;
      }
      this.drones = JSON.parse(await get(`${HOST}auth/skyzr/drones`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          orgId: this.user.orgId
        }
      }));

      this.dronesById = this.drones.reduce((lookup, drone) => {
        lookup[drone.serial] = drone;
        return lookup;
      }, {});

      this.update();
    } catch (err) {
      console.error(new Error(err));
      this.drones = [];
      this.update();
    }
  }

  i18n () {
    return '';
  }

  update () {
    if (this.updating) {
      return;
    }
    this.updating = requestAnimationFrame(() => {
      this.updating = null;

      this.app.update(this);
    });
  }
}

function getQuery () {
  const results = {};

  location.search.slice(1).split('&').forEach(part => {
    const [key, value] = part.split('=');

    if (value == null) {
      results[key] = true;
    } else {
      results[key] = decodeURIComponent(value);
    }
  });

  return results;
}

function noOp () {}
