const { fetch, redom } = window;
const { el } = redom;

export default class Privacy {
  constructor () {
    this.el = el('.privacy');
    fetch('privacy.html')
      .then(res => {
        if (!res.ok) throw new Error(res.status);
        return res;
      })
      .then(res => res.text())
      .then(html => {
        this.el.innerHTML = html.replace('</h2>', '</h2><div class="menu-content">') + '</div>';
      });
  }
}
