import { alertTypes } from './notifications.js';
import { humanTime } from './menu-operationplans.js';

const { redom } = window;
const { el, list, place } = redom;

export default class Notifications {
  constructor ({ app, api, i18n }) {
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el('.notifications',
      this.notificationsText = el('h2'),
      el('.menu-content',
        this.noNotifications = place(this.noNotificationsText = el('p')),
        this.notifications = list('.notifications', Notification, '_id', { app, api })
      )
    );
  }

  update (data) {
    const { i18n } = this;
    const { allNotifications } = data;

    this.notificationsText.textContent = i18n('notifications');
    this.noNotificationsText.textContent = i18n('notifications.empty');

    this.noNotifications.update(!allNotifications.length);
    this.notifications.update(allNotifications);
  }
}

class Notification {
  constructor () {
    this.el = el('.notification',
      this.title = el('.notification-title'),
      this.text = el('.notification-text'),
      this.date = el('.notification-date')
    );
  }

  update (data) {
    const { severity, type, text, createdAt } = data;

    const humanType = alertTypes[type];

    this.title.textContent = severity + (humanType ? `: ${humanType}` : '');
    this.text.textContent = text;
    this.date.textContent = humanTime(new Date(createdAt));
  }
}
