import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

export default class CapacitorSetup {
  constructor(api) {
    if (Capacitor.isNative) {
      if (Capacitor.getPlatform() === "ios") {
        this.setStyle("light");
        window.addEventListener("statusTap", function () {
          console.log("statusbar tapped");
        });
      } else if (Capacitor.getPlatform() === "android") {
        StatusBar.setOverlaysWebView({ overlay: false });
      }
    }
  }

  async setStyle(style) {
    await StatusBar.setStyle({
      style: style === "dark" ? Style.Dark : Style.Light,
    });
  }

  async show() {
    await StatusBar.show();
  }

  async hide() {
    await StatusBar.hide();
  }
}
