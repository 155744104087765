const {
  redom
} = window;
const { el, list, place } = redom;

export default class OperationPlans {
  constructor ({ app, api, i18n }) {
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el('.operationplans',
      this.title = el('h2'),
      this.operationplansTitle = el('h3'),
      el('.menu-content',
        this.noOperationPlans = place(this.noOperationPlansText = el('p')),
        this.operationPlans = list('.operation-plans', OperationPlan, 'operationPlanId', { app, api, i18n })
      ),
      this.waitingPlans = place(el('div',
        this.waitingplansTitle = el('h3'),
        el('.menu-content',
          this.waitingOperationPlans = list('.waiting-plans', OperationPlan, 'operationPlanId', { app, api, rejected: true, i18n })
        )
      )),
      this.cancelledPlans = place(el('div',
        this.cancelledplansTitle = el('h3'),
        el('.menu-content',
          this.cancelledOperationPlans = list('.cancelled-operation-plans', OperationPlan, 'operationPlanId', { app, api, rejected: true, i18n })
        )
      )),
      this.rejectedPlans = place(el('div',
        this.rejectedplansTitle = el('h3'),
        el('.menu-content',
          this.rejectedOperationPlans = list('.rejected-operation-plans', OperationPlan, 'operationPlanId', { app, api, rejected: true, i18n })
        )
      )),
      this.logsTitle = el('h3'),
      el('.menu-content',
        this.noLogs = place(this.noLogsText = el('p')),
        this.logs = list('.logs', Log, 'operationPlanId', { app, api })
      )
    );
  }

  onmount () {
    this.api.fetchPlans(true);
  }

  update ({ activePlans, waitingPlans, rejectedPlans, loggedPlans, cancelledPlans }) {
    const { i18n } = this;
    this.title.textContent = i18n('sections.operationplans');
    this.operationplansTitle.textContent = i18n('operationplans.activated');
    this.waitingplansTitle.textContent = i18n('operationplans.waiting');
    this.cancelledplansTitle.textContent = i18n('operationplans.cancelled');
    this.rejectedplansTitle.textContent = i18n('operationplans.rejected');
    this.logsTitle.textContent = i18n('logs');
    this.noOperationPlansText.textContent = i18n('operationplans.empty');
    this.noLogsText.textContent = i18n('logs.empty');

    this.noOperationPlans.update(!activePlans.length);
    this.operationPlans.update(activePlans);

    this.waitingPlans.update(waitingPlans.length);
    this.waitingOperationPlans.update(waitingPlans);

    this.cancelledPlans.update(cancelledPlans.length);
    this.cancelledOperationPlans.update(cancelledPlans);

    this.rejectedPlans.update(rejectedPlans.length);
    this.rejectedOperationPlans.update(rejectedPlans);
    this.noLogs.update(!loggedPlans.length);
    this.logs.update(loggedPlans);
  }
}

class Log {
  constructor ({ api }, data) {
    this.api = api;
    this.el = el('.log',
      this.name = el('p.name'),
      el('p.meta', { style: { fontSize: '.875rem' } },
        this.date = el('span'),
        ' | ',
        this.time = el('span'),
        ' | '
      )
    );
  }

  update (data) {
    const { operationPlanId, publicInfo } = data;
    const { title } = publicInfo;

    this.name.textContent = title || '';

    const { operationTrajectory, operationVolumes } = data;

    this.el.onclick = (e) => {
      if (e.actionClick) {
        return;
      }
      const { lng, lat } = getPoint(operationTrajectory, operationVolumes);

      this.api.app.map.map.flyTo({ center: [lng, lat], zoom: 12 });

      this.api.menuSection = 'operationplan';
      this.api.menuId = operationPlanId;
      this.api.update();
    };

    const startDate = getStartDate(operationVolumes);
    const endDate = getEndDate(operationVolumes);

    this.date.textContent = humanDate(new Date(startDate));
    this.time.textContent = `${humanTime(new Date(startDate))}-${humanTime(new Date(endDate))}`;
  }
}

class OperationPlan {
  constructor ({ app, api, rejected, i18n }) {
    this.i18n = i18n;
    this.rejected = rejected;
    this.app = app;
    this.api = api;
    this.el = el('.operation-plan',
      this.status = el('.status'),
      this.name = el('p.name'),
      el('p.meta', { style: { fontSize: '.875rem' } },
        this.date = el('span'),
        ' | ',
        this.time = el('span')
      )
    );
  }

  update (data) {
    const { i18n } = this;
    const { operationPlanId, publicInfo, state, operationTrajectory, operationVolumes } = data;
    const { title } = publicInfo;

    this.status.className = `status ${state}`;
    this.status.textContent = i18n(`operationplan.state.${state}`);

    this.name.textContent = title || i18n('unnamed');

    this.el.onclick = (e) => {
      if (e.actionClick) {
        return;
      }
      const { lng, lat } = getPoint(operationTrajectory, operationVolumes);

      this.api.app.map.map.flyTo({ center: [lng, lat], zoom: 12 });

      this.api.menuSection = 'operationplan';
      this.api.menuId = operationPlanId;
      this.api.update();
    };

    const startDate = getStartDate(operationVolumes);
    const endDate = getEndDate(operationVolumes);

    this.date.textContent = humanDate(new Date(startDate));
    this.time.textContent = `${humanTime(new Date(startDate))}-${humanTime(new Date(endDate))}`;
  }
}

export function humanDate (date) {
  const d = date.getDate();
  const m = date.getMonth() + 1;
  const y = date.getFullYear();
  return `${d}.${m}.${y}`;
}

export function humanTime (date) {
  const h = date.getHours();
  const min = date.getMinutes();
  return `${pad(h)}:${pad(min)}`;
}

function pad (val) {
  return `0${val}`.slice(-2);
}

function getPoint (trajectory, volumes) {
  if (trajectory) {
    const { trajectoryElements } = trajectory;
    const { longitude: lng, latitude: lat } = trajectoryElements[0];

    return { lng, lat };
  } else {
    const { operationGeometry } = volumes[0];

    const [lng, lat] = operationGeometry.geom.coordinates[0][0];

    return { lng, lat };
  }
}

function getStartDate (volumes) {
  return new Date(volumes[0].timeBegin);
}

function getEndDate (volumes) {
  return new Date(volumes[volumes.length - 1].timeEnd);
}
