import getPlanArea from './get-plan-area.js';
import { getItem } from './storage.js';

const {
  alert,
  fetch,
  io,
  redom,
  maplibregl,
  turf,
  location,
  requestAnimationFrame,
  mlcontour,
  ENV
} = window;
const { el } = redom;
const { TS } = ENV;

const demSource = new mlcontour.DemSource({
  url: `https://${location.hostname}${location.pathname}map/terrain_${TS}/{z}/{x}/{y}`,
  encoding: 'terrarium',
  maxzoom: 13,
  worker: true,
  cacheSize: 100,
  timeoutMs: 10000
});
demSource.setupMaplibre(maplibregl);

export default class Map {
  constructor ({ app, api, i18n }) {
    this.i18n = i18n;
    this.app = app;
    this.api = api;
    this.el = el('#map');
    window.addEventListener('resize', () => {
      this.map && this.map.resize();
    });
  }

  update (api) {
    this.updateFeatures();

    if (this.mapLoaded) {
      if (api.drawPlan || api.createPlan) {
        if (api.drawPlan) {
          this.canvasContainer.style.cursor = 'crosshair';
        } else {
          this.canvasContainer.style.cursor = '';
        }
        if (this.marker) {
          this.marker.remove();
          this.marker = null;
        }
        const { route, routeArea, routePoints, routeMidpoints } = getPlanArea(api);

        route ? this.map.getSource('route').setData(route) : this.emptySource('route');
        routeArea ? this.map.getSource('route-area').setData(routeArea) : this.emptySource('route-area');
        routePoints ? this.map.getSource('route-points').setData(routePoints) : this.emptySource('route-points');
        routeMidpoints ? this.map.getSource('route-midpoints').setData(routeMidpoints) : this.emptySource('route-midpoints');
      } else {
        this.canvasContainer.style.cursor = '';

        try {
          this.emptySource('route');
          this.emptySource('route-area');
          this.emptySource('route-points');
          this.emptySource('route-midpoints');
        } catch (err) {
          console.error(new Error(err));
        }
      }
    }
  }

  async onmount () {
    const { api, i18n } = this;

    const { location } = window;
    const { AVIAMAPS_COUNTRY, HOST, TS, BASE_URL, RELATIVE_PATH } = window.ENV;

    const { center, boundingBox } = AVIAMAPS_COUNTRY;

    let bounds;

    if (location.hash.length < 2) {
      bounds = [
        boundingBox.slice(0, 2),
        boundingBox.slice(2, 4)
      ];
    }

    const { basemap, dark } = parseQuery();

    if (basemap) {
      this.api.basemap = basemap;
    }

    const id = await getItem('id');
    let style = `${HOST}map${TS ? `_${TS}` : ''}/style.json?ts=${TS}&id=${id}&lang=${i18n.LANG}`;

    if (basemap) {
      style += `&basemap=${basemap}`;
    }

    if (dark) {
      style += '&dark';
    }

    if (window.ENV.THEME === 'austrocontrol') {
      style += '&austrocontrol';
    } else if (window.ENV.THEME === 'eans') {
      style += '&eans';
    }

    this.map = new maplibregl.Map({
      container: this.el,
      style,
      center,
      zoom: 5,
      hash: 'p',
      fadeDuration: 0,
      attributionControl: false
    });

    this.map.on('render', () => {
      const weatherVisible = this.map.querySourceFeatures('weather').length;

      if (this.altitudeSelector) {
        this.altitudeSelector.style.opacity = weatherVisible ? '' : 0;
        this.altitudeSelector.style.pointerEvents = weatherVisible ? '' : 'none';
      }

      clearTimeout(this.updatingFeatures);
      this.updatingFeatures = setTimeout(() => {
        if (this.marker) {
          this.updateFeatures();
          api.update();
        }
      }, 1000);
    });

    this.map.once('render', () => {
      try {
        const zoomIn = document.querySelector('.maplibregl-ctrl-zoom-in');
        zoomIn.title = zoomIn.ariaLabel = i18n('mapcontrols.zoomIn');

        const zoomOut = document.querySelector('.maplibregl-ctrl-zoom-out');
        zoomOut.title = zoomOut.ariaLabel = i18n('mapcontrols.zoomOut');

        const resetBearing = document.querySelector('.maplibregl-ctrl-compass');
        resetBearing.title = resetBearing.ariaLabel = i18n('mapcontrols.resetBearing');

        const findLocation = document.querySelector('.maplibregl-ctrl-geolocate');
        findLocation.title = findLocation.ariaLabel = i18n('mapcontrols.findLocation');
      } catch (err) {
        console.error(new Error(err));
      }
      this.updateFeatures();
      if (!this.mapLoaded) {
        this.mapLoaded = true;

        if (bounds) {
          this.map.fitBounds(bounds, {
            padding: 32
          });
        }

        api.update();

        this.api.fetchUAS();

        this.map.on('mouseenter', 'route-point-symbols', (e) => {
          this.canvasContainer.style.cursor = 'move';
        });

        this.map.on('mouseenter', 'route-midpoint-symbols', (e) => {
          this.canvasContainer.style.cursor = 'move';
        });

        this.map.on('mouseleave', 'route-midpoint-symbols', (e) => {
          this.canvasContainer.style.cursor = 'crosshair';
        });

        this.map.on('mouseleave', 'route-point-symbols', (e) => {
          this.canvasContainer.style.cursor = 'crosshair';
        });

        const onDown = (e) => {
          e.preventDefault();
          e.originalEvent.preventDefault();

          const feature = e.features[0];

          const currentPoint = getCurrentPoint(feature, api);

          const startLocation = {
            ...currentPoint
          };

          const onMove = (e) => {
            e.preventDefault();
            e.originalEvent.preventDefault();

            const { lng, lat } = e.lngLat;

            currentPoint.lng = lng;
            currentPoint.lat = lat;

            api.update();
          };

          const onUp = () => {
            this.map.off('mousemove', onMove);
            this.map.off('mouseup', onUp);

            this.map.off('touchmove', onMove);
            this.map.off('touchend', onUp);

            const { currentDrawing } = api;

            const data = [currentDrawing.concat(api.currentDrawing[0]).map(({ lng, lat }) => [lng, lat])];

            if (api.drawMode === 'polygon') {
              const kinks = turf.kinks(turf.polygon(data)).features.length;
              if (kinks) {
                alert(i18n('draw.error.intersectingPolygon'));
                currentPoint.lng = startLocation.lng;
                currentPoint.lat = startLocation.lat;
                api.update();
              }
            }
          };

          this.map.on('mousemove', onMove);
          this.map.on('mouseup', onUp);

          this.map.on('touchmove', onMove);
          this.map.on('touchend', onUp);
        };

        this.map.on('mousedown', 'route-midpoint-symbols', onDown);
        this.map.on('mousedown', 'route-point-symbols', onDown);
        this.map.on('touchstart', 'route-midpoint-symbols', onDown);
        this.map.on('touchstart', 'route-point-symbols', onDown);

        if (basemap === 'topo') {
          this.map.addSource('hillshade', {
            type: 'raster-dem',
            encoding: 'terrarium',
            tiles: [demSource.sharedDemProtocolUrl],
            maxzoom: 13,
            tileSize: 256
          });

          this.map.addSource('contours', {
            type: 'vector',
            tiles: [
              demSource.contourProtocolUrl({
                multiplier: 1,
                thresholds: {
                  0: [200, 1000],
                  12: [100, 500],
                  14: [50, 200],
                  15: [20, 100]
                },
                contourLayer: 'contours',
                elevationKey: 'ele',
                levelKey: 'level',
                extent: 4096,
                buffer: 1
              })
            ],
            maxzoom: 15
          });

          this.map.addLayer({
            id: 'hillshade',
            type: 'hillshade',
            source: 'hillshade',
            layout: {
              visibility: 'visible'
            },
            paint: {
              'hillshade-exaggeration': [
                'interpolate', ['linear'], ['zoom'],
                0, 0.001,
                5, 0.01,
                11, 0.1
              ]
            }
          }, 'waterway-tunnel');

          this.map.addLayer({
            id: 'contour-lines',
            type: 'line',
            source: 'contours',
            'source-layer': 'contours',
            paint: {
              'line-color': '#000',
              'line-opacity': [
                'interpolate', ['linear'], ['zoom'],
                0, 0.1,
                11, 0.25
              ],
              'line-width': [
                'match',
                ['get', 'level'],
                1,
                1,
                0.375
              ]
            }
          }, 'waterway-tunnel');

          this.map.addLayer({
            id: 'contour-labels',
            type: 'symbol',
            source: 'contours',
            'source-layer': 'contours',
            filter: ['>', ['get', 'level'], 0],
            layout: {
              'symbol-placement': 'line',
              'text-size': 8,
              'text-field': ['concat', ['number-format', ['get', 'ele'], {}], ' m'],
              'text-font': ['Inter Semibold']
            },
            paint: {
              'text-halo-color': 'white',
              'text-halo-width': 1
            }
          }, 'waterway-tunnel');
        }
      }
      if (this.marker) {
        const lookup = {};
        const lngLat = this.marker.getLngLat();

        const renderedFeatures = this.map.querySourceFeatures('operationplans')
          .concat(this.map.querySourceFeatures('uas'));

        api.features = renderedFeatures
          .filter(feature => {
            const { hidden } = feature.properties;

            return !hidden;
          })
          .filter(feature => {
            try {
              const point = turf.point([lngLat.lng, lngLat.lat]);

              if (feature.geometry.type === 'LineString') {
                feature.geometry = turf.lineToPolygon(feature).geometry;
              }

              return turf.booleanPointInPolygon(point, feature);
            } catch (err) {
              console.error(new Error(err));
              return false;
            }
          })
          .filter(feature => {
            const id = feature.id || feature.properties.name || feature.properties.code;
            if (id && !lookup[id]) {
              lookup[id] = true;
              return true;
            }
            return false;
          });
        api.sortFeatures();
        api.app.info.update(api.features && api.features.length, api);
      } else {
        api.features = null;
        api.app.info.update(null, api);
      }
    });

    this.canvasContainer = this.el.querySelector('.maplibregl-canvas-container');

    this.attr = new maplibregl.AttributionControl();
    this.map.addControl(this.attr, 'bottom-right');

    this.geo = new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      showUserHeading: true,
      trackUserLocation: true
    });
    this.map.addControl(this.geo, 'bottom-right');

    this.nav = new maplibregl.NavigationControl({
      visualizePitch: true
    });
    this.map.addControl(this.nav, 'bottom-right');

    const scale = new maplibregl.ScaleControl({
      maxWidth: 70,
      unit: 'imperial'
    });
    this.map.addControl(scale, 'top-right');

    scale.setUnit('metric');

    this.map.on('click', (e) => {
      if (api.drawPlan || api.createPlan) {
        return;
      }
      this.marker = (this.marker || new maplibregl.Marker({
        color: 'hsl(217, 83%, 32%)'
      }))
        .setLngLat(e.lngLat)
        .addTo(this.map);

      this.point = e.point;
      this.updateFeatures();
      api.update();

      const point = this.map.project(e.lngLat);
      const features = this.map.queryRenderedFeatures(point);

      for (const feature of features) {
        if (feature.source === 'operationplans') {
          this.api.menuclick = true;
          this.app.menubutton.el.click();
          this.api.menuSection = 'operationplan';
          this.api.menuId = feature.properties.id;
          this.api.operationPlan = {
            ...JSON.parse(feature.properties.originalPlan),
            _fromGeoJSON: true
          };
          this.api.update();

          setTimeout(() => {
            this.api.menuclick = false;
          }, 100);

          break;
        }
      }
    });

    fetch('https://gis.utm-labs-frequentis.com/geoserver/vaisala/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=vaisala%3Avaisala&outputFormat=application%2Fjson')
      .then(res => {
        if (!res.ok) throw new Error(res.status);
        return res;
      })
      .then(res => res.json())
      .then(geojson => {
        this.weather = geojson;
        const altitudes = {};

        for (const feature of geojson.features) {
          altitudes[feature.properties.altitude] = true;
        }

        this.altitudeSelector = el('input.altitudeselector', {
          type: 'range',
          value: Math.min(...Object.keys(altitudes)),
          min: Math.min(...Object.keys(altitudes)),
          max: Math.max(...Object.keys(altitudes))
        });
        this.el.appendChild(this.altitudeSelector);

        const oninput = () => {
          const closestDistance = Object.keys(altitudes).map((altitude) => {
            return [Math.abs(altitude - this.altitudeSelector.value), Number(altitude)];
          }).sort((a, b) => {
            return a[0] - b[0];
          })[0];

          const weather = this.map.getSource('weather');

          weather && weather.setData({
            ...geojson,
            features: geojson.features.filter(feature => {
              return Number(feature.properties.altitude) === closestDistance[1];
            })
          });
        };

        this.altitudeSelector.oninput = oninput;

        oninput();
      });

    const socket = io(BASE_URL, {
      path: RELATIVE_PATH + 'socket.io/',
      auth: {
        id: await getItem('id')
      }
    });

    const DATA = this.api.DATA;

    function onVisibilityChange () {
      if (document.hidden) {
        socket.disconnect();
      } else {
        socket.connect();
      }
    }

    document.addEventListener('visibilitychange', onVisibilityChange);
    socket.on('connect', () => {
      onVisibilityChange();

      DATA.alerts = [];
    });

    socket.on('time', time => {
      DATA.lastTime = Math.round(Date.now() / 1000);
    });

    socket.on('alert', alert => {
      const [_id, createdAt, longitude, latitude, text, severity, type, operationPlanIds, acknowledged] = alert;
      DATA.alerts.unshift({ _id, createdAt, longitude, latitude, text, severity, type, operationPlanIds, acknowledged });
      this.api.update();
    });

    socket.on('operationplan', () => {
      api.fetchPlans();
    });

    socket.on('uas', () => {
      api.fetchUAS();
    });

    socket.on('telemetry', data => {
      const [_id, name, createdAt, latitude, longitude, velocity, altitudeMeters, type, icon, opacity] = data;
      const existing = DATA.telemetryLookup[name];

      if (existing) {
        for (let i = 0; i < DATA.telemetry.length; i++) {
          const item = DATA.telemetry[i];

          if (item === existing) {
            DATA.telemetry.splice(i--, 1);
          }
        }
      }
      const telemetry = { _id, name, createdAt, latitude, longitude, velocity, altitudeMeters, type, icon, opacity };
      DATA.telemetryLookup[name] = telemetry;
      DATA.telemetry.push(telemetry);

      if (!this.updatingTelemetry) {
        this.updatingTelemetry = requestAnimationFrame(() => {
          this.updatingTelemetry = null;
          const telemetry = this.map.getSource('telemetry');
          telemetry && telemetry.setData({
            type: 'FeatureCollection',
            features: DATA.telemetry.map(telemetry => {
              const { latitude, longitude, velocity, altitudeMeters, type, name, icon, opacity } = telemetry;

              let speed;
              let bearing;

              let visibleData = `${name}`;

              if (altitudeMeters) {
                visibleData += `\n${altitudeMeters.replace('M', 'm')}`;
              }

              if (velocity) {
                const { latitude: latitudeDelta, longitude: longitudeDelta } = velocity;
                const latitudeEnd = latitude + latitudeDelta;
                const longitudeEnd = longitude + longitudeDelta;

                const point1 = turf.point([longitude, latitude]);
                const point2 = turf.point([longitudeEnd, latitudeEnd]);

                speed = turf.distance(point1, point2, { units: 'kilometers' }) * 1000;

                visibleData += `\n${Math.round(speed * 3.6)} km/h`;

                const bearingValue = turf.bearing(point1, point2);

                if (speed) {
                  visibleData += ` (${humanBearing(bearingValue)}°)`;
                }

                if (type === 'AIRCRAFT') {
                  bearing = bearingValue;
                }
              }

              return {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [longitude, latitude]
                },
                properties: {
                  name,
                  altitudeMeters,
                  type,
                  speed,
                  bearing,
                  visibleData,
                  icon,
                  opacity
                }
              };
            })
          });
        });
      }
    });

    setInterval(() => {
      const DATA = this.api.DATA;
      for (let i = 0; i < DATA.alerts.length; i++) {
        const alerts = DATA.alerts[i];

        if (alerts.createdAt < Date.now() - 60 * 60 * 1000) {
          DATA.alerts.splice(i--, 1);
        }
      }

      for (let i = 0; i < DATA.telemetry.length; i++) {
        const telemetry = DATA.telemetry[i];

        if (telemetry.createdAt < Date.now() - 60 * 1000) {
          DATA.telemetry.splice(i--, 1);
        }
      }
    }, 1000);
  }

  updateFeatures () {
    if (this.marker) {
      const lngLat = this.marker.getLngLat();
      const visited = {};

      const renderedFeatures = this.map.querySourceFeatures('operationplans').map(feature => {
        return {
          ...feature,
          geometry: feature.geometry,
          source: 'operationplans'
        };
      })
        .concat(this.map.querySourceFeatures('uas').map(feature => {
          return {
            ...feature,
            geometry: feature.geometry,
            source: 'uas'
          };
        }));

      const features = [
        {
          source: 'coordinate',
          properties: {
            name: `${humanCoordinate(lngLat)}`
          }
        }
      ].concat(renderedFeatures
        .filter(feature => {
          const { hidden } = feature.properties;

          return !hidden;
        })
        .filter(feature => {
          try {
            const point = turf.point([lngLat.lng, lngLat.lat]);

            if (feature.geometry.type === 'LineString') {
              feature.geometry = turf.lineToPolygon(feature).geometry;
            }

            const pointInPolygon = turf.booleanPointInPolygon(point, feature);

            return pointInPolygon;
          } catch (err) {
            console.error(new Error(err));
            return false;
          }
        })
        .filter(feature => {
          if (feature.source === 'uas') {
            const { zoneId, identifier } = feature.properties;

            const id = zoneId || identifier;

            if (visited[id]) {
              return false;
            }

            visited[id] = true;
          }
          return true;
        })
      );

      this.api.features = features
        .filter(feature => {
          return feature.source === 'uas' || feature.source === 'coordinate';
        });

      this.api.sortFeatures();
    }
  }

  reloadJSON (sourceName) {
    const source = this.map.getSource(sourceName);
    source && source.setData(source._data);
  }

  emptySource (sourceName) {
    this.map.getSource(sourceName).setData({
      type: 'FeatureCollection',
      features: []
    });
  }

  onunmount () {
    this.map.destroy();
  }
}

function getCurrentPoint (feature, api) {
  const { geometry, properties, source } = feature;
  const { i, pointIndex } = properties;
  const [lng, lat] = geometry.coordinates;

  const midpoint = source === 'route-midpoints';

  if (midpoint) {
    const newPoint = {
      lng,
      lat
    };
    api.currentDrawing.splice(
      pointIndex,
      0,
      newPoint
    );
    api.update();
    return newPoint;
  }

  return api.currentDrawing[i];
}

function parseQuery () {
  return location.search.slice(1).split('&').map((part) => {
    return part.split('=');
  }).reduce((lookup, [key, value]) => {
    if (value == null) {
      lookup[key] = true;
    } else {
      lookup[key] = value;
    }
    return lookup;
  }, {});
}

function humanBearing (val) {
  return `00${((Math.round(val) % 360) + 360) % 360}`.slice(-3);
}

function humanCoordinate (latLng) {
  return `${latLng.lat.toFixed(6)}, ${latLng.lng.toFixed(6)}`;
}
