import { getItem, setItem } from './storage.js';

const { redom } = window;
const { el } = redom;

export default class Banner {
  constructor ({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = el('.banner',
      this.content = el('div'),
      this.close = el('button.button.close', el('i.ti.ti-x'))
    );
  }

  update (data) {
    const { i18n, api } = this;
    this.content.textContent = i18n('BANNER');
    this.close.onclick = async () => {
      api.bannerAcknowledged = true;
      api.update();
      await setItem('bannerAcknowledged', i18n('__checksums.BANNER'));
    };
  }
}
