import { Preferences } from '@capacitor/preferences';

export async function setItem (key, value) {
  return await Preferences.set({ key, value });
}

export async function removeItem (key) {
  return await Preferences.remove({ key });
}

export async function getItem (key) {
  const { value } = await Preferences.get({ key });

  return value;
}
