import { post } from './request.js';

const { redom } = window;

const { el, list } = redom;

export const alertTypes =
  'OPERATION_ROGUE LAND_IMMEDIATELY CONTACT_THE_TOWER CAUTION_TRAFFIC_NEARBY CONFIRM_DRONE_LANDED OTHER_SEE_FREE_TEXT LAND_IMMEDIATELY_AND_CONFIRM_DRONE_LANDED OPERATION_OUT_OF_TOWER OPERATION_NONCONFORMING OPERATION_CONFORMING OPERATION_PLAN_STATE_CHANGED OPERATION_PLAN_PROPOSED'
    .split(' ')
    .map((str) => {
      return [str, str[0] + str.slice(1).replace(/_/g, ' ').toLowerCase()];
    })
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

export default class Notifications {
  constructor ({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = list('#notifications', Notification, '_id', { api, i18n });
  }

  update (api) {
    this.el.update(api.notifications);
  }
}

class Notification {
  constructor ({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      '.notification',
      el(
        '.notification-content',
        el(
          'table',
          el(
            'tr',
            el(
              'td',
              (this.icon = el(
                '.notification-icon',
                el('i.ti.ti-alert-triangle')
              ))
            ),
            el(
              'td',
              (this.title = el('.notification-title')),
              (this.text = el('.notification-text'))
            ),
            el('td', (this.ok = el('button.button', 'Acknowledge')))
          )
        )
      )
    );
  }

  remove () {
    const { _id } = this.data;

    this.el.animate([
      { opacity: 1 },
      { opacity: 0 }
    ], {
      duration: 1000,
      iterations: 1,
      fill: 'both',
      timingFunction: 'ease-in-out'
    });

    setTimeout(() => {
      for (let i = 0; i < this.api.DATA.alerts.length; i++) {
        const alert = this.api.DATA.alerts[i];

        if (alert._id === _id) {
          this.api.DATA.alerts.splice(i--, 1);
        }
      }
      this.api.update();
    }, 1000);
  }

  update (data) {
    const { i18n } = this;
    const { _id, severity, type, text } = data;
    const { HOST } = window.ENV;

    const DATA = this.api.DATA;

    const humanType = i18n(`notifications.type.${type}`, alertTypes[type]);
    const humanSeverity = i18n(`notifications.severity.${severity}`, severity);

    if (severity === 'AUTO_ACKNOWLEDGE') {
      this.ok.parentNode.style.display = 'none';
      setTimeout(() => {
        this.remove();
      }, 5000);
      this.title.textContent = humanType;
      const split = text.split(' –> ');

      if (split.length > 1) {
        const from = split[0];
        const to = split[1];

        const humanFrom = i18n(`operationplan.state.${from}`, from);
        const humanTo = i18n(`operationplan.state.${to}`, to);

        this.text.textContent = `${humanFrom} –> ${humanTo}`;
      } else {
        this.text.textContent = text;
      }
    } else {
      this.ok.parentNode.style.display = '';
      this.title.textContent = humanSeverity + (humanType ? `: ${humanType}` : '');
      this.text.textContent = text;
    }

    this.ok.onclick = async () => {
      try {
        await post(`${HOST}utm/alert/acknowledge/${_id}`);
      } catch (err) {
        console.error(new Error(err));
      }

      for (let i = 0; i < DATA.alerts.length; i++) {
        const alert = DATA.alerts[i];

        if (alert._id === _id) {
          alert.acknowledged = true;
          this.api.update();
        }
      }
    };
    this.data = data;
  }
}
