import Draw from './draw.js';

const { alert, redom, FileReader, toGeoJSON, DOMParser } = window;
const { el } = redom;

const MIN_BUFFER_VALUE = 30;

export default class DrawControl {
  constructor ({ api, app, i18n }) {
    this.api = api;
    this.app = app;
    this.i18n = i18n;

    !api.drawingMode && (api.drawingMode = 'circle');
    !api.buffer && (api.buffer = MIN_BUFFER_VALUE);

    const map = (this._map = app.map.map);
    this.draw = new Draw({ map, drawControl: this, api, i18n });
    this.el = el(
      '.draw-control',
      (this.title = el('b')),
      el('br'),
      el(
        '.tabselect',
        (this.circle = el('.tab.selected')),
        (this.line = el('.tab')),
        (this.polygon = el('.tab'))
      ),
      el('br'),
      (this.instructions = el('p', { style: { margin: '.75rem 0' } })),
      el(
        'table',
        el(
          'tr',
          (this.radiusText = el('td')),
          el(
            'td',
            (this.buffer = el(
              'input',
              {
                type: 'range',
                value: MIN_BUFFER_VALUE,
                min: MIN_BUFFER_VALUE,
                max: 2000,
                step: 50
              },
              { style: { marginRight: '0.5rem', verticalAlign: 'middle' } }
            ))
          ),
          el(
            'td',
            (this.bufferValue = el('input', {
              size: 5,
              value: MIN_BUFFER_VALUE,
              style: {
                verticalAlign: 'middle'
              }
            }))
          ),
          el('td', ' m')
        ) /*,
        el('tr', { style: { display: 'none' } },
          this.maxSegmentText = el('td'),
          el('td',
            this.segment = el('input', {
              type: 'range',
              value: 0.2,
              min: 0.1,
              max: 10,
              step: 0.1
            }, { style: { marginRight: '0.5rem', verticalAlign: 'middle' } })
          ),
          el('td',
            this.segmentValue = el('input', {
              size: 3,
              value: 0.2,
              style: {
                verticalAlign: 'middle'
              }
            })
          ),
          el('td', ' km')
        ) */
      ),
      el(
        'div',
        (this.importText = el('p', {
          style: { marginTop: '.75rem', marginBottom: '.25rem' }
        })),
        el('br'),
        el('.button.fileinput',
          el('i.ti.ti-file-upload'),
          this.fileinputText = el('span'),
          (this.fileinput = el('input', { type: 'file' }))
        )
      ),
      el(
        'div',
        { style: { marginTop: '1.5rem', textAlign: 'right' } },
        el(
          'div',
          { style: { display: 'inline-block', marginRight: '.5rem' } },
          (this.cancel = el('.button.grey.circle', el('i.ti.ti-x')))
        ),
        el(
          'div',
          { style: { display: 'inline-block' } },
          (this.create = el('.button.circle', el('i.ti.ti-arrow-right')))
        )
      )
    );

    this.cancel.onclick = () => {
      api.drawPlan = null;
      api.update();
    };

    const buttonNames = ['circle', 'line', 'polygon'];

    this.fileinput.onchange = (e) => {
      if (api.drawingMode !== 'polygon') {
        this.polygon.onclick();
      }
      const reader = new FileReader();

      reader.addEventListener('load', (e) => {
        try {
          const isKml = e.target.result.includes('<kml');
          const isGpx = e.target.result.includes('<gpx');

          const xml = (str) => new DOMParser().parseFromString(str, 'text/xml');

          const json = isKml
            ? toGeoJSON.kml(xml(e.target.result))
            : isGpx
              ? toGeoJSON.gpx(xml(e.target.result))
              : JSON.parse(e.target.result);

          if (json.operationTrajectory) {
            this.api.currentDrawing =
              json.operationTrajectory.trajectoryElements.map(
                ({ latitude, longitude }) => {
                  return { lng: longitude, lat: latitude };
                }
              );
            this._map.fitBounds(
              [
                [
                  Math.min(
                    ...this.api.currentDrawing.map((point) => point.lng)
                  ),
                  Math.min(...this.api.currentDrawing.map((point) => point.lat))
                ],
                [
                  Math.max(
                    ...this.api.currentDrawing.map((point) => point.lng)
                  ),
                  Math.max(...this.api.currentDrawing.map((point) => point.lat))
                ]
              ],
              { padding: 32 }
            );
            // this.segment.oninput();
            this.api.update();
          } else if (json.features) {
            const { features } = json;

            for (let i = 0; i < features.length; i++) {
              const feature = features[i];

              if (feature.geometry.type === 'LineString') {
                if (api.drawingMode !== 'line') {
                  this.line.onclick();
                }
                this.api.currentDrawing = feature.geometry.coordinates.map(
                  ([lng, lat]) => {
                    return { lng, lat };
                  }
                );
                // this.segment.oninput();
                this.api.update();
                this._map.fitBounds(
                  [
                    [
                      Math.min(
                        ...this.api.currentDrawing.map((point) => point.lng)
                      ),
                      Math.min(
                        ...this.api.currentDrawing.map((point) => point.lat)
                      )
                    ],
                    [
                      Math.max(
                        ...this.api.currentDrawing.map((point) => point.lng)
                      ),
                      Math.max(
                        ...this.api.currentDrawing.map((point) => point.lat)
                      )
                    ]
                  ],
                  { padding: 32 }
                );
                break;
              } else if (feature.geometry.type === 'Polygon') {
                this.api.currentDrawing = feature.geometry.coordinates[0].map(
                  ([lng, lat]) => {
                    return { lng, lat };
                  }
                );
                // this.segment.oninput();
                this.api.update();
                this._map.fitBounds([
                  [
                    Math.min(
                      ...this.api.currentDrawing.map((point) => point.lng)
                    ),
                    Math.min(
                      ...this.api.currentDrawing.map((point) => point.lat)
                    )
                  ],
                  [
                    Math.max(
                      ...this.api.currentDrawing.map((point) => point.lng)
                    ),
                    Math.max(
                      ...this.api.currentDrawing.map((point) => point.lat)
                    )
                  ]
                ]);
              }
            }
          }
        } catch (err) {
          console.error(new Error(err.stack));
          alert(err);
        }

        this.fileinput.value = '';
      });

      reader.readAsText(e.target.files[0]);
    };

    this.create.onclick = () => {
      if (!api.currentDrawing.length) {
        return alert(i18n('draw.error.emptyPath'));
      }
      if (api.drawingMode === 'polygon') {
        if (api.currentDrawing.length < 3) {
          return alert(i18n('draw.error.areaIncomplete'));
        }
      }
      api.drawPlan = false;
      api.createPlan = true;
      api.update();
    };

    buttonNames.forEach((name) => {
      this[name].onclick = () => {
        if (this[name].classList.contains('selected')) {
          return;
        }
        buttonNames.forEach((name) => {
          this[name].classList.remove('selected');
        });
        this[name].classList.add('selected');

        api.drawingMode = name;

        /* if (name === 'line') {
          this.segment.parentNode.parentNode.style.display = '';
        } else {
          this.segment.parentNode.parentNode.style.display = 'none';
        } */

        if (!api.currentDrawing.length) {
          if (name === 'circle') {
            const { lat, lng } = this._map.getCenter();
            api.currentDrawing = [
              {
                lat,
                lng
              }
            ];
          } else {
            api.currentDrawing = [];
          }
        }

        api.update();
      };
    });

    [this.buffer, this.bufferValue].forEach((input) => {
      input.oninput = () => {
        const { value } = input;

        if (input === this.buffer) {
          this.bufferValue.value = Math.max(MIN_BUFFER_VALUE, value || MIN_BUFFER_VALUE);
        } else {
          this.buffer.value = Math.max(MIN_BUFFER_VALUE, value || MIN_BUFFER_VALUE);
        }

        api.buffer = Number(value);
        api.update();
      };
      input.onchange = () => {
        let { value } = input;

        const min = Number(this.buffer.min);
        const max = Number(this.buffer.max);
        const current = Number(value);

        if (current < min) {
          value = min;
          this.bufferValue.value = value;
        } else if (current > max) {
          value = this.bufferValue.value = max;
        }
        api.buffer = current;
      };
    });

    /* [this.segment, this.segmentValue].forEach(input => {
      input.oninput = () => {
        const { value } = input;

        if (input === this.segment) {
          this.segmentValue.value = value || 0.1;
        } else {
          this.segment.value = value || 0.1;
        }

        api.segment = Number(value);
        api.update();
      };
      input.onchange = () => {
        let { value } = input;

        if (value < this.segment.min) {
          value = input.value = this.segment.min;
        }

        if (Number(value) < 0.1) {
          value = 0.1;
          this.segmentValue.value = value;
        } else if (Number(value) > 100) {
          value = 100;
          this.segmentValue.value = value;
        }
      };
    }); */
  }

  onmount () {
    this.el.classList.add('slide-in');
  }

  onunmount () {
    this.draw.onunmount();
  }

  update (api) {
    const { i18n } = this;

    this.fileinputText.textContent = i18n('draw.upload');

    this.title.textContent = i18n('draw.title');

    this.circle.textContent = i18n('draw.circular');
    this.line.textContent = i18n('draw.route');
    this.polygon.textContent = i18n('draw.area');
    this.instructions.textContent = i18n('draw.instructions');
    this.radiusText.textContent = i18n('draw.radius');
    this.importText.textContent = i18n('draw.import');
    // this.maxSegmentText.textContent = i18n('draw.maxSegment');

    if (api.drawPlan) {
      if (api.drawPlan !== this.drawPlan) {
        this[api.drawingMode].click();
      }
    }
    this.drawPlan = api.drawPlan;
    if (!api.currentDrawing.length) {
      this.create.classList.add('disabled');
    } else if (api.drawingMode === 'polygon' && api.currentDrawing.length < 3) {
      this.create.classList.add('disabled');
    } else {
      this.create.classList.remove('disabled');
    }
  }
}
