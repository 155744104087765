const { redom } = window;
const { el } = redom;

export default class Online {
  constructor ({ api }) {
    this.api = api;
    this.el = el('#online', (this.icon = el('i.ti.ti-link-off')));
    this.check();
  }

  check () {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.check();
    }, 1000 - (Date.now() % 1000));

    const diff = Math.abs((this.api.DATA.lastTime || 0) * 1000 - Date.now());
    const online = diff < 10 * 1000;

    if (online) {
      this.el.className = 'green';
      this.icon.classList.add('ti-link');
      this.icon.classList.remove('ti-link-off');
    } else {
      this.el.className = 'red';
      this.icon.classList.remove('ti-link');
      this.icon.classList.add('ti-link-off');
    }
  }

  update (data) {}
}
