import { get, post } from './request.js';

const { alert, confirm, redom, HOST } = window;
const { el } = redom;

export default class Emergency {
  constructor({ app, api, i18n }) {
    this.api = api;
    this.el = el('.emergency', el('i.ti.ti-alert-triangle'));
    this.el.onclick = () => {
      const { user } = this.api;

      if (!user) {
        return alert(i18n('operationplan.error.signInFirst'));
      }

      get(`${HOST}utm/operationplans.json?active`).then((json) => {
        const operationplans = JSON.parse(json);
        if (!operationplans.length) {
          return alert(i18n('operationplan.error.noCurrentOperationPlans'));
        }
        if (confirm(i18n('emergency.confirm'))) {
          post(
            `${HOST}utm/declarealert?planId=${operationplans[0].operationPlanId}`
          )
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      });
    };
  }
}
