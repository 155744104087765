const { redom } = window;
const { el } = redom;

export default class MenuButton {
  constructor({ app, api, i18n }) {
    this.api = api;

    this.el = el('#menu-button', el('i.ti.ti-menu'));

    this.el.onmousedown =
      this.el.ontouchstart =
      this.el.onclick =
        (e) => {
          e.menuclick = true;
          api.menuOpened = true;
          api.update();

          clearTimeout(this.buttonTimeout);
          this.buttonTimeout = setTimeout(() => {
            window.addEventListener('touchstart', this);
            window.addEventListener('mousedown', this);
          }, 0);
        };
  }

  handleEvent(e) {
    setTimeout(() => {
      if (this.api.menuclick) {
        return;
      }
      this.api.menuOpened = false;
      this.api.update();

      window.removeEventListener('touchstart', this);
      window.removeEventListener('mousedown', this);
    }, 0);
  }

  update(data) {}
}
