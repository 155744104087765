import { humanDate, humanTime } from './menu-operationplans.js';
import { get, post } from './request.js';

const { alert, confirm, redom, turf } = window;
const { el } = redom;

// TEXTUAL_RESTRICTION, AUTHORITY_REQUIREMENTS
// not rejective

export default class OperationPlan {
  constructor ({ app, api, i18n }) {
    const { HOST } = window.ENV;
    this.app = app;
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      '.operation-plan',
      this.mainTitle = el('h2'),
      (this.title = el('h3')),
      el(
        '.menu-content',
        el(
          'table.table',
          el(
            'tr',
            (this.startTimeText = el('td')),
            (this.startTime = el('td.right'))
          ),
          el(
            'tr',
            (this.endTimeText = el('td')),
            (this.endTime = el('td.right'))
          ),
          el(
            'tr',
            (this.durationText = el('td')),
            (this.duration = el('td.right'))
          ),
          el(
            'tr',
            (this.maxAltitudeText = el('td')),
            (this.maxAltitude = el('td.right'))
          ),
          el(
            'tr',
            (this.typeOfOperationText = el('td')),
            (this.typeOfOperation = el('td.right'))
          )
        ),
        (this.message = el('.message', { style: { overflow: 'auto' } })),
        (this.openRemarks = el('button.button', {
          style: { marginTop: '1rem' }
        })),
        (this.remarks = el('.remarks')),
        (this.checklist = el('.checklist')),
        (this.cancel = el('button.button.red', {
          style: { marginTop: '.5rem', marginRight: '.25rem' }
        })),
        (this.action = el('button.button.white', {
          style: { marginTop: '.5rem' }
        }))
      )
    );

    this.openRemarks.onclick = async (e) => {
      e.actionClick = true;

      const { data } = this;

      const { operationplansLookup, menuId } = data;
      const operationPlan = operationplansLookup[menuId] || data.operationPlan;

      if (this.remarks.style.display !== '') {
        this.openRemarks.textContent = i18n('remarks.hide');
        this.openRemarks.classList.add('grey');
        this.remarks.style.display = '';
        this.remarks.textContent = '';

        const { operationPlanId } = operationPlan;

        const data = JSON.parse(await get(`${HOST}utm/log/${operationPlanId}`));

        if (data.length === 0) {
          data.push({});
        }

        const log = data[data.length - 1];

        const textarea = document.createElement('textarea');

        textarea.textContent = log.additionalInformation;

        this.remarks.appendChild(textarea);

        const br = document.createElement('br');
        this.remarks.appendChild(br);

        const save = document.createElement('button');
        save.classList.add('button');
        save.textContent = i18n('save');
        this.remarks.appendChild(save);

        save.onclick = async (e) => {
          e.actionClick = true;

          if (textarea.value === textarea.textContent) {
            this.remarks.style.display = 'none';
            this.openRemarks.textContent = i18n('remarks.open');
            return;
          }
          if (!log.operationPlanId) {
            log.operationPlanId = operationPlanId;
            log.creationDateTime = new Date();
          }
          log.updateDateTime = new Date();
          log.additionalInformation = textarea.value;

          await post(`${HOST}utm/log/${operationPlanId}`, {
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(log)
          });
          this.remarks.style.display = 'none';
          this.openRemarks.textContent = i18n('remarks.open');
        };
      } else {
        this.openRemarks.textContent = i18n('remarks.open');
        this.openRemarks.classList.remove('grey');
        this.remarks.style.display = 'none';
      }
    };
    this.remarks.style.display = 'none';
  }

  update (data) {
    const { i18n } = this;
    const { operationplansLookup, menuId } = data;
    const { HOST, MAX_ACTIVATION_LEAD_TIME } = window.ENV;

    const operationPlan = operationplansLookup[menuId] || data.operationPlan;

    this.mainTitle = i18n('operationplan');

    this.startTimeText.textContent = i18n('operationplan.startTime');
    this.endTimeText.textContent = i18n('operationplan.endTime');
    this.durationText.textContent = i18n('operationplan.duration');
    this.maxAltitudeText.textContent = i18n('operationplan.maxAltitude');
    this.typeOfOperationText.textContent = i18n('operationplan.operationType');
    if (this.remarks.style.display !== '') {
      this.openRemarks.textContent = i18n('remarks.open');
    }
    this.cancel.textContent = i18n('operationplan.cancel');

    if (!operationPlan) {
      return;
    }

    const volumes =
      operationPlan.operationVolumes &&
      operationPlan.operationVolumes.map((volume) =>
        turf.feature(volume.operationGeometry.geom)
      );

    const {
      publicInfo = {},
      operationVolumes = [],
      operationPlanId,
      operationTrajectory = [],
      rejected,
      state,
      typeOfOperation
    } = operationPlan;

    if (operationPlanId !== this.operationPlanId) {
      this.operationPlanId = operationPlanId;
      if (volumes) {
        const geojson = {
          type: 'FeatureCollection',
          features: volumes
        };
        this.app.map.map.getSource('highlight').setData(geojson);

        const bbox = turf.bbox(geojson);

        this.app.map.map.fitBounds(
          [
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]]
          ],
          { padding: 32, maxZoom: 15 }
        );
      } else {
        this.app.map.map.getSource('highlight').setData({
          type: 'FeatureCollection',
          features: []
        });
      }
    }

    const { title = '' } = publicInfo;
    const startTime = new Date(operationVolumes[0].timeBegin);
    const endTime = new Date(
      operationVolumes[operationVolumes.length - 1].timeEnd
    );

    const duration = Math.round((endTime - startTime) / 1000);
    const maxAltitude = Math.max(
      operationPlan.operationVolumes.map((volume) => {
        const { operationGeometry } = volume;
        const { maxAltitude } = operationGeometry;
        const { altitudeValue, unitsOfMeasure } = maxAltitude;

        return altitude(altitudeValue, unitsOfMeasure, i18n);
      })
    );

    this.title.textContent = title || i18n('unnamed');
    this.startTime.textContent =
      humanDate(new Date(startTime)) + ' ' + humanTime(new Date(startTime));
    this.endTime.textContent = humanDate(new Date(endTime)) + ' ' + humanTime(new Date(endTime));
    this.duration.textContent = humanDuration(duration);
    this.maxAltitude.textContent = maxAltitude;
    this.typeOfOperation.textContent = typeOfOperation === 'REMOTELY_PILOTED_VLOS' ? 'VLOS' : 'BVLOS';

    this.checklist.textContent = '';

    this.cancel.onclick = async (e) => {
      e.actionClick = true;

      const onclick = this.cancel.onclick;
      const textContent = this.cancel.textContent;

      if (confirm(i18n('operationplan.cancel.confirm'))) {
        this.cancel.onclick = () => {};
        this.cancel.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';

        try {
          await post(`${HOST}utm/operationplan/${operationPlanId}/cancel`, {
            body: JSON.stringify(operationPlan)
          });
          this.api.menuSection = 'operationplans';
          this.api.menuId = '';
          this.api.update();
        } catch (err) {
          alert(err);
        }
        this.cancel.onclick = onclick;
        this.cancel.textContent = textContent;
      }
    };

    if (rejected) {
      const conflictLookup = {};
      const message = (
        (
          (operationPlan.authorization &&
            operationPlan.authorization.conflicts) ||
          []
        ).concat(
          (operationPlan.activation && operationPlan.activation.conflicts) || []
        ) || []
      ).reduce((message, conflict) => {
        const nonrejectiveType =
          conflict.conflictType === 'TEXTUAL_RESTRICTION' ||
          conflict.conflictType === 'AUTHORITY_REQUIREMENTS';

        const color = nonrejectiveType
          ? 'hsl(60, 50%, 50%)'
          : 'hsl(0, 50%, 50%)';

        if (conflict.conflictType === 'TEXTUAL_RESTRICTION' && conflict.objectType === 'UAS_ZONE') {
          /*
          const phrase = 'operationplan.conflict.textualRestriction.uasZone';
          message += `<br><div style="float: right; width: 1em; height: 1em; border-radius: 50%; background-color: ${color}; margin-left: .5rem;"></div><b>${i18n(phrase, conflict.message).replace('{{objectId}}', conflict.objectId)}</b><br>`;
          */
        } else {
          if (conflict.message) {
            if (!conflictLookup[conflict.message]) {
              conflictLookup[conflict.message] = true;
              message += `<br><div style="float: right; width: 1em; height: 1em; border-radius: 50%; background-color: ${color}; margin-leftshou: .5rem;"></div><b>${conflict.message}</b><br>`;
            }
          }
        }

        /* if (conflict.conflictType === 'AUTHORITY_REQUIREMENTS') {
          (conflict.authorityRequirements || conflict.authorityRequirement || []).forEach((requirement) => {
            const { contactName, email, name, service, phone, purpose } =
              requirement;

            const procedure = {
              AUTHORIZATION: 'Please ask for authorization:',
              NOTIFICATION: 'Please inform the organization about the flight:',
              INFORMATION: 'Please ask the organization for information:'
            };

            message += `<br>${procedure[purpose]}<br>${name} ${service}<br>${contactName}<br>${phone}<br>${email}<br>`;
          });
        } */

        return message;
      }, '');

      if (message !== this._message) {
        this.message.innerHTML = message;
        this._message = message;
      }
      this.action.textContent = i18n('operationplan.resubmit');
      this.action.onclick = async (e) => {
        e.actionClick = true;

        this.api.menuOpened = false;

        if (this.app.fly.view) {
          this.app.fly.view.button.click();
        }

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        const { trajectoryElements } = operationTrajectory;
        const { latitude: startLat, longitude: startLng } =
          trajectoryElements[0];
        const { latitude: endLat, longitude: endLng } =
          trajectoryElements[trajectoryElements.length - 1];

        this.api.drawingMode =
          trajectoryElements.length > 1
            ? startLat === endLat && startLng === endLng
              ? 'polygon'
              : 'line'
            : 'circle';

        this.app.draw.view[this.api.drawingMode].click();

        this.api.currentDrawing = operationTrajectory.trajectoryElements.map(
          ({ latitude, longitude }) => {
            return { lng: longitude, lat: latitude };
          }
        );

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        this.api.update();

        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });

        this.app.map.map.fitBounds(
          [
            [
              Math.min(...this.api.currentDrawing.map((point) => point.lng)),
              Math.min(...this.api.currentDrawing.map((point) => point.lat))
            ],
            [
              Math.max(...this.api.currentDrawing.map((point) => point.lng)),
              Math.max(...this.api.currentDrawing.map((point) => point.lat))
            ]
          ],
          { padding: 32, maxZoom: 15 }
        );
        this.api.resubmitPlan = operationPlan;
        this.app.draw.view.create.click();
      };
      this.action.style.display = '';
      this.cancel.style.display = 'none';
    } else {
      this.message.textContent = '';
      this._message = '';
      if (state === 'PROPOSED') {
        this.action.textContent = i18n('operationplan.waitingForApproval');
        this.action.onclick = (e) => {
          e.actionClick = true;
        };
      } else if (state === 'APPROVED') {
        if (operationPlan.operator === this.api.operator) {
          this.checklist.innerHTML = i18n('checklist');
        }

        if (this.activating) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
        } else {
          this.action.textContent = i18n('operationplan.activatePlan');
        }

        this.action.onclick = this.activating
          ? () => {}
          : async (e) => {
            e.actionClick = true;

            this.action.textContent = i18n('operationplan.activating');

            const { operationVolumes } = operationPlan || {};
            const startTime = new Date(operationVolumes[0].timeBegin);

            if (startTime) {
              if (
                startTime.getTime() >
                Date.now() + MAX_ACTIVATION_LEAD_TIME * 60 * 1000
              ) {
                this.action.textContent = i18n('operationplan.activatePlan');
                return alert(
                  i18n('operationplan.error.startAfterMaxActivationLeadTime')
                );
              }
            }

            this.activating = true;

            this.update(this.data);

            try {
              await post(
                `${HOST}utm/operationplan/${operationPlanId}/activate`,
                {
                  body: JSON.stringify(operationPlan)
                }
              );
            } catch (err) {
              alert(err);
            }
            this.activating = false;
            this.update(this.data);
          };
        this.action.style.display = '';
      } else if (state === 'TAKEOFFREQUESTED') {
        this.action.textContent = i18n('operationplan.takeoffRequested');
        this.action.style.display = '';
      } else if (state === 'ACTIVATED') {
        if (this.activating) {
          this.action.innerHTML = '<i class="ti ti-loader-2 ti-spin"></i>';
        } else {
          this.action.textContent = i18n('operationplan.endFlight');
        }

        this.action.onclick = this.activating
          ? () => {}
          : async (e) => {
            e.actionClick = true;

            this.activating = true;
            this.update(this.data);

            try {
              await post(
                `${HOST}utm/operationplan/${operationPlanId}/endflight`,
                operationPlan
              );
              this.api.menuSection = 'operationplans';
              this.api.menuId = '';
              this.api.update();
            } catch (err) {
              alert(err);
            }
            this.activating = false;

            this.update(this.data);
          };
        this.cancel.style.display = 'none';
      } else {
        this.action.style.display = 'none';
        this.action.textContent = '';
        this.action.onclick = (e) => {
          e.actionClick = true;
        };
        this.cancel.style.display = 'none';
      }
    }
    if (operationPlan._fromGeoJSON) {
      this.openRemarks.style.display = 'none';
      this.cancel.style.display = 'none';
      this.action.style.display = 'none';
      /* } else {
        this.openRemarks.style.display = '';
        this.cancel.style.display = '';
        this.action.style.display = ''; */
    }
    this.data = data;
  }

  onmount () {
    this.api.fetchPlans(true);
  }

  onunmount () {
    this.app.map.map.getSource('highlight').setData({
      type: 'FeatureCollection',
      features: []
    });
  }
}

function humanDuration (time) {
  if (time < 60) {
    return '00:' + pad(time);
  } else if (time < 3600) {
    return pad(Math.floor(time / 60)) + ':' + pad(time % 60);
  } else {
    return (
      pad(Math.floor(time / 3600)) +
      ':' +
      pad(Math.floor(time / 60) % 60) +
      ':' +
      pad(time % 60)
    );
  }
}

function pad (str) {
  return ('0' + str).slice(-2);
}

function altitude (value, unit, i18n) {
  if (Number.isNaN(Number(value))) {
    return i18n('unknown');
  } else if (unit === 'FT') {
    return Math.round(0.3048 * value);
  } else {
    return Math.round(value);
  }
}
