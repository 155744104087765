const { redom } = window;
const { el } = redom;

export default class Fly {
  constructor ({ app, api, i18n }) {
    this.api = api;
    this.el = el(
      '.fly',
      (this.button = el('#fly-button', el('i.ti.ti-drone')))
    );
    this.button.onclick = () => {
      if (api.menuOpened) {
        api.menuOpened = false;
        api.menuSection = '';
      }
      if (!api.drawPlan) {
        const { marker } = api.app.map;

        if (marker) {
          const { lng, lat } = marker.getLngLat();

          api.currentDrawing = [{ lng, lat }];
        } else {
          api.currentDrawing = [];
        }
      } else {
        api.currentDrawing = [];
      }

      api.drawPlan = !api.drawPlan;
      api.update();
    };
  }

  update () {
    this.opened = this.api.drawPlan;
  }
}
