const { turf } = window;

export default function getPlanArea (api) {
  const results = {};

  if (api.currentDrawing.length) {
    const count = api.currentDrawing.length;
    const data = (api.drawingMode === 'polygon' && count > 2) ? api.currentDrawing.concat(api.currentDrawing[0]) : api.currentDrawing;

    const lines = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            name: ''
          },
          geometry: {
            type: (count < 2 ? 'Point' : 'LineString'),
            coordinates: count < 2
              ? [data[0].lng, data[0].lat]
              : data.map(({ lng, lat }) => {
                return [lng, lat];
              })
          }
        }
      ]
    };

    if (api.drawingMode === 'polygon' && count > 2) {
      lines.features[0].geometry.type = 'Polygon';
      lines.features[0].geometry.coordinates = [lines.features[0].geometry.coordinates];
    }

    const points = {
      type: 'FeatureCollection',
      features: data.map(({ lng, lat }, i) => {
        return {
          type: 'Feature',
          properties: {
            i
          },
          geometry: {
            type: 'Point',
            coordinates: [lng, lat]
          }
        };
      })
    };

    const midpoints = {
      type: 'FeatureCollection',
      features: data.reduce((results, leg, i) => {
        if (i) {
          const prevLeg = data[i - 1];
          const { midpoint, point } = turf;
          const feature = midpoint(point([prevLeg.lng, prevLeg.lat]), point([leg.lng, leg.lat]));
          feature.properties.pointIndex = i;
          results.push(feature);
        }
        return results;
      }, []).map((feature, i) => {
        return {
          ...feature,
          properties: {
            ...feature.properties || {},
            i
          }
        };
      })
    };

    /*
    const chunkedLines = api.drawingMode === 'line' ? turf.lineChunk(lines, api.segment, { units: 'kilometers' }) : lines;

    if (api.drawingMode !== 'polygon' || count > 2) {
      const { buffer } = turf;
      results.routeArea = buffer(chunkedLines, api.buffer / 1000, { units: 'kilometers' });
    } else {
      results.routeArea = chunkedLines;
    }
    results.route = chunkedLines;
    */

    const chunkedLines = api.drawingMode === 'line' ? chunk(lines) : lines;

    const { buffer } = turf;
    results.routeArea = buffer(chunkedLines, api.buffer / 1000, { units: 'kilometers' });
    results.route = lines;
    results.routePoints = points;
    if (api.drawPlan) {
      results.routeMidpoints = midpoints;
    }
  }

  return results;
}

function chunk (geojson) {
  const { features } = geojson;

  const { geometry } = features[0];
  const { coordinates } = geometry;

  if (geometry.type === 'Point') {
    return geojson;
  }

  let previousPoint;

  return {
    type: 'FeatureCollection',
    features: coordinates.reduce((results, point) => {
      if (previousPoint) {
        results.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [previousPoint, point]
          }
        });
      }
      previousPoint = point;
      return results;
    }, [])
  };
}
