import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { getItem } from './storage.js';

const platform = Capacitor.getPlatform();

const { redom, location, confirm } = window;
const { el } = redom;

export default class Bottombar {
  constructor ({ api, i18n }) {
    this.api = api;
    this.i18n = i18n;
    this.el = el(
      '#bottombar',
      el('.logo'),
      el('.user', (this.userIcon = el('i.ti.ti-user')), (this.user = el('p')))
    );
  }

  update (data) {
    const { i18n } = this;
    const { user } = data;
    const { HOST, APP_ID } = window.ENV;

    if (user) {
      const signOutLink = document.createElement('a');
      signOutLink.href = '#';
      signOutLink.textContent = `${i18n('auth.signOut')}`;
      signOutLink.onclick = async () => {
        if (confirm(i18n('auth.signOut.confirm'))) {
          const id = await getItem('id');
          let url = `${HOST}auth/skyzr/logout`;

          if (APP_ID) {
            url += `?app=${APP_ID}`;
            url += `&id=${id}`;
          } else {
            url += `?id=${id}`;
          }

          openLink(url);
        }
      };
      this.user.textContent = '';
      this.user.appendChild(signOutLink);
      this.user.appendChild(document.createTextNode(' | '));
      const profileLink = document.createElement('a');
      profileLink.textContent = user.name || 'User';
      profileLink.href = '#';
      profileLink.onclick = (e) => {
        e.preventDefault();
        const url = `${HOST}auth/skyzr/profile${
          APP_ID ? `?app=${APP_ID}` : ''
        }`;

        openLink(url, true);
      };
      this.user.appendChild(profileLink);
    } else {
      const signInLink = document.createElement('a');
      signInLink.textContent = `${i18n('auth.signIn')} / ${i18n(
        'auth.signUp'
      )}`;
      signInLink.href = '#';
      signInLink.onclick = (e) => {
        e.preventDefault();
        const url = `${HOST}auth/skyzr/login${APP_ID ? `?app=${APP_ID}` : ''}`;

        openLink(url);
      };
      this.user.textContent = '';
      this.user.appendChild(signInLink);
    }
  }
}

function openLink (url, newWindow) {
  if (platform !== 'web') {
    Browser.open({ url });
  } else {
    if (newWindow) {
      window.open(url);
    } else {
      location.href = url;
    }
  }
}
