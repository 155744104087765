import Feature from './info-feature.js';

const { redom } = window;
const { el, list } = redom;

export default class Info {
  constructor ({ app, api, i18n }) {
    this.el = el(
      '.info',
      (this.features = list('.features', Feature, null, { api, i18n })),
      (this.close = el('.close', el('i.ti.ti-x')))
    );
    this.close.onclick = () => {
      const { marker } = api.app.map;
      marker && marker.remove();
      api.app.map.marker = null;
      api.features = [];
      api.update();
    };
  }

  update (api) {
    this.features.update(api.features || []);
  }
}
