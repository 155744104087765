export default function roundWgs (val) {
  const degrees = val | 0;
  const decimals = val - degrees;

  const minutes = (decimals * 60) | 0;

  const seconds = Math.round((decimals * 60 - minutes) * 60);

  return degrees + minutes / 60 + seconds / 3600;
}
